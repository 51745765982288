.custom-table td:first-child,
.custom-table th:first-child {
  border-radius: 5px 0 0 5px;
}

.custom-table td:last-child,
.custom-table th:last-child {
  border-radius: 0 5px 5px 0;
}

.custom-table th:first-child {
  padding-left: 0px;
}

.custom-table th:last-child {
  padding-right: 0px;
}
/* width */
.scrollNavbar::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
.scrollNavbar::-webkit-scrollbar-thumb {
  background: rgb(214, 214, 214);
  border-radius: 10px;
}

/* Handle on hover */
.scrollNavbar::-webkit-scrollbar-thumb:hover {
  background: #727272;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}