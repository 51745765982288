@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

/* html {
  scroll-behavior: smooth;
} */
@font-face {
  font-family: "Effra";
  src: url(/public/fonts/Effra.woff2) format("woff2");
}
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #0f2837;
  overflow-x: hidden;
  /* user-select: none; */
}

@layer utilities {
  .trapezoid-clip-path {
    clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
  }
}

@layer components {
  .leaderBoard {
    background-image: url("./assets/images/leaderBoardBg.png");
    background-size: 100% 100%;
  }
  .profileTier {
    background-image: url("./assets/images/profileTierBg.png");
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 20px;
    background: grey;
  }

  ::-webkit-scrollbar-thumb:active {
    border-radius: 20px;
  }
}

.reward-hero {
  background-image: url("/public/images/Half-Diamond-Pattern\ 1.svg");
  background-repeat: no-repeat;
  background-position: right;
}

.main-bg {
  background-image: url("/public/images/Frame\ 1362.svg"),
    url("/public/images/Frame\ 1363.svg");
  background-repeat: no-repeat;
  background-position: top 50px left, top 50px right;
}
.support-bg {
  background-image: url("/public/images/Frame\ 1362.svg"),
    url("/public/images/Frame\ 1363.svg"),
    url("/public/images/Half-Diamond-Pattern\ 3.svg"),
    url("/public/images/Half-Diamond-Pattern\ 2.svg");
  background-repeat: no-repeat;
  background-position: top 50px left, top 50px right;
}
@media (max-width: 650px) {
  .support-bg {
    background-image: url("/public/images/Half-Diamond-Pattern\ 3.svg"),
      url("/public/images/Half-Diamond-Pattern\ 2.svg");
    background-repeat: no-repeat;
    background-position: top 50px left, top 50px right;
  }
}
.blue-overlay {
  animation: blue-big 1500ms linear forwards;
  width: 5px;
  height: 5px;
  background-color: #0028b3;
  position: absolute;
  z-index: 100000;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

@keyframes blue-big {
  0% {
    width: "5px";
    height: "5px";
  }
  100% {
    transform: scale(2000);
  }
}
